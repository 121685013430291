


<template functional>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <p>
          <span>COPYRIGHT &copy; </span>
          <span>{{ new Date().getFullYear() }} </span>
          <span>CMS.ZA</span>
          <!--<span class="hidden sm:inline-block">, All rights Reserved</span>-->
        </p>
        <span class="md:flex hidden items-center">
            <span>v.1.0.0</span>
        </span>
    </footer>
</template>

<script>
export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        }
    }
}
</script>
