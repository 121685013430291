export default [
  {
    name: 'Dashboard',
    icon: 'HomeIcon',
    url: '/',
    slug: 'home'
  },
  {
    header: 'Config',
    icon: 'PackageIcon',
    items: [
      {
        url: '/config/nav_menu',
        name: 'Menu',
        slug: 'menu',
        icon: 'MenuIcon'
      },
      /*
      {
        url: '/config/roles',
        name: 'Role',
        slug: 'role',
        icon: 'CommandIcon'
      },
      */
      {
        url: '/config/options',
        name: 'Options',
        slug: 'options',
        icon: 'SettingsIcon'
      },
      {
        url: '/users',
        name: 'User',
        slug: 'user',
        icon: 'UsersIcon'
      }
    ]
  },
  {
    header: 'Content',
    icon: 'LayersIcon',
    items: [
      {
        url: '/content/post/filter/all',
        name: 'Post',
        slug: 'post',
        icon: 'ClipboardIcon'
      },
      {
        url: '/content/pages/filter/all',
        name: 'Pages',
        slug: 'pages',
        icon: 'LayoutIcon'
      },
      {
        url: '/content/foto/filter/all',
        name: 'Foto',
        slug: 'foto',
        icon: 'CameraIcon'
      },
      {
        url: '/content/event/filter/all',
        name: 'Event',
        slug: 'event',
        icon: 'CalendarIcon'
      }
      /*,
      {
        url: '/content/comment/filter/all',
        name: 'Comment',
        slug: 'comment',
        icon: 'MessageCircleIcon'
      }
      */
    ]
  },
  {
    header: 'Pengajuan',
    icon: 'InboxIcon',
    items: [
      {
        url: '/pengajuan/informasi-publik/filter/all',
        name: 'Informasi Publik',
        slug: 'informasi-publik',
        icon: 'InfoIcon'
      },
    ]
  }
]

