import axios from '@/axios.js'

const state = () => ({
    searchs: [],
})

const mutations = {
  SET_SEARCHS (state, payload) {
    state.searchs = payload
  },
}

const actions = {
  fetchSearch ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/search-all')
        .then((response) => {
          commit('SET_SEARCHS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}